$(function() {
  const slickOptions = {
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 100,
    speed: 3000,
    cssEase: 'linear',
    slidesToShow: 1,
    variableWidth: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    swipe: false,
    swipeToSlide: false,
    touchMove: false,
  }
  $('#user-way-logo-slider-1').slick(slickOptions);
  $('#user-way-logo-slider-2').slick(slickOptions);
})
