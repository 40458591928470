// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// import 'font-awesome/scss/font-awesome.scss'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap/dist/js/bootstrap'

// import '../mattrz/modal.bootstrap'
var jQuery = require('jquery')
// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import '../stylesheets/mattrz.scss'
import '../mattrz/form-validation'
import 'jquery-validation'
import 'js-cookie'
import '../mattrz/alert'

import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel'

import 'slick-carousel/slick/slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import * as services from '../mattrz/service.function'
require("mattrz/cookieprivacy")
require("mattrz/balancechart")
// import('../mattrz/userWayLogoSlider')
import 'slick-carousel/slick/slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
require('mattrz/userWayLogoSlider')
window.services = services

services.main()

const images = require.context("../stylesheets/images", true)
const imagePath = name => images(name, true)

import Rails from "@rails/ujs"

Rails.start()
