import Cookies from 'js-cookie'
class CookieBar {
  constructor() {
    this.cookiesBar = document.getElementById('cookies-bar');
    //alert(this.cookiesBar.innerHTML);
  }

  init() {
    // if (this.cookiesAllowed()) {
    // }

    this.cookieProcess();
  }

  // cookiesAllowed() {
  //   return Cookies.get('mattrz_allow_cookies') === 'yes';
  // }

  cookieProcess() {
    if (!this.cookiesBar) {
      return;
    }

    $( ".p-cookie__close" ).click(function() {
      //1．ウェブサイトの動作に必要不可欠なクッキー
      Cookies.set('mattrz_allow_cookies', 'yes', {
        expires: 365
      });
      //機能性クッキー
      Cookies.set('functionality_cookies', 'yes', {
        expires: 365
      });
      //3．パフォーマンスクッキー
      Cookies.set('perform_cookies', 'yes', {
        expires: 365
      });
      //4．ソーシャルメディアクッキー
      Cookies.set('social_cookies', 'yes', {
        expires: 365
      });

      $("#cookies-bar").hide();
    });


    $( ".p-cookie__btn__resolve" ).click(function() {
      //1．ウェブサイトの動作に必要不可欠なクッキー
      Cookies.set('mattrz_allow_cookies', 'yes', {
        expires: 365
      });
      //機能性クッキー
      Cookies.set('functionality_cookies', 'yes', {
        expires: 365
      });
      //3．パフォーマンスクッキー
      Cookies.set('perform_cookies', 'yes', {
        expires: 365
      });
      //4．ソーシャルメディアクッキー
      Cookies.set('social_cookies', 'yes', {
        expires: 365
      });

      $("#cookies-bar").hide();
    });

    $( ".p-cookie__btn__reject" ).click(function() {
      Cookies.set('mattrz_allow_cookies', 'no', {
        expires: 365
      });
      $("#cookies-bar").hide();
    });

  }

}

window.onload = function() {
  const cookieBar = new CookieBar();

  cookieBar.init();
}
