import Highcharts from 'highcharts'
import addMore from "highcharts/highcharts-more";
import addDrilldown from "highcharts/modules/drilldown";
import addNoData from "highcharts/modules/no-data-to-display";
import addExporting from "highcharts/modules/exporting";
import addExporData from "highcharts/modules/export-data";
import addAccessibility from "highcharts/modules/accessibility";
import ScrollHint from 'scroll-hint'
addMore(Highcharts);
addDrilldown(Highcharts);
addNoData(Highcharts);
addExporting(Highcharts);
addExporData(Highcharts);
addAccessibility(Highcharts);

(function (define) {
  define(['jquery'], function ($) {
    return function () {
      var web_highcharts = {
        init: init,
        init_pie: init_pie,
        init_scrollhint: init_scroll
      };
      function init_scroll(obj) {
        new ScrollHint(obj, {
          applyToParents: true,
          i18n: {
            scrollable: 'スクロールできます'
          }
        });
      }
      function init_pie(id,series_pie) {
        Highcharts.setOptions({
          lang: {
            thousandsSep: ' ',
            noData: ''
          },
          noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px'
            }
          },
          credits: {
            enabled: false
          },
          exporting: {
            enabled: false
          }
        })
        Highcharts.chart(id, {
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: 'pie'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            tooltip: {
              pointFormat: '<b>{point.name}</b><br><b>{point.percentage:.1f}%</b>',
              shadow: true,
              enabled: false
            },
            accessibility: {
              point: {
                valueSuffix: '%'
              }
            },
            plotOptions: {
              series: {
                  states: {
                    inactive: {
                      opacity: 1
                    }
                  },
                  enableMouseTracking: false
              },
              pie: {
                allowPointSelect: false,
                cursor: 'pointer',
                colors: [ '#F81312','#F94241','#FB7171','#FCA1A0','#FED0D0','#D6D6D6'],
                shadow:false,
                dataLabels: {
                  enabled: true,
                  format: '{point.percentage:.1f} %',
                  distance: -50,
                  filter: {
                    property: 'percentage',
                    operator: '>',
                    value: 6
                  }
                }
              }
            },
            series: series_pie
        });

      }

      function init(id, categories, series, chart_type) {
        Highcharts.setOptions({
          lang: {
            thousandsSep: ' ',
            noData: ''
          },
          noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px'
            }
          },
          credits: {
            enabled: false
          },
          exporting: {
            enabled: false
          },
          colors: [ '#FED0D0','#FCA1A0','#FA5A59','#F81312']
        })
        Highcharts.chart(id, {
            chart: {
                type: chart_type,
                zoomType: 'y',
                //backgroundColor:"#FBFAE4",
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: categories,
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            tooltip: {
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                },
            },
            series: series
        });

      }
      return web_highcharts;
    }();
  });
})(typeof define === 'function' && define.amd ? define : function (deps, factory) {
      if (typeof module !== 'undefined' && module.exports) {
        //Node
        module.exports = factory(require('jquery'));
      } else {
        window.web_highcharts = factory(window.jQuery);
      }
});
